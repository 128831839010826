<!--
 * @Description: 折一用户协议
 * @Date: 2019-12-21 18:38:11
 * @LastEditTime : 2019-12-30 17:22:11
 * @LastEditors  : 白青
 -->
<template>
  <div id='user-agreement'>
    <h1 class="title">折一用户协议</h1>
    <div class="content">
      <p>“折一”在此特别提醒用户认真阅读、充分理解本《“折一”帐号注册协议》（下称《协议》）中各条款，包括免除或者限制折一责任的免责条款及对用户的权利限制条款，特别是法律适用、争议解决方式等条款。除非您接受本《协议》所有条款，否则您无权注册、申请“折一”帐号及其相关服务。</p>
      <p>1.折一帐号的所有权归折一，用户完成申请注册后，获得折一帐号的使用权。折一有权利在不告知用户的前提下，使用折一内的相关数据。</p>
      <p>2.用户可以通过注册折一帐号使用折一提供的相应服务，用户使用折一的服务时，须同时遵守各项服务的服务条款。</p>
      <p>3.用户注册折一帐号后如果长期不使用，折一有权回收帐号，以免造成资源浪费，由此带来的包括并不限于用户通信中断、用户资料丢失、用户账号无法正常使用等损失由用户自行承担。</p>
      <p>希望用户承诺并保证：</p>
      <p>1. 用户不得发布颠覆国家政权、破坏民族团结、敏感信息、淫秽色情信息等危害信息，不得伪造活动信息，不得发布垃圾广告，不得发布折一不允许发布的信息。</p>
      <p>2. 用户请按照商品的详情页进行推广，做到推广信息与真实情况一致，不得作虚假宣传，不得误导消费者，违规推广，严格按照先行法律法规执行推广活动。用户应当确保在折一发布的图片、文字以及其他各类推广内容信息真实、准确、合法完整，无假冒伪劣商品，无虚假或夸大描述的情形，保证不违反法律，不侵犯任何人的合法权益。</p>
      <p>3.用户应明确理解并同意本协议所有内容，若因用户违反法律或本协议规定，在折一发布的图片、文字以及其他各类推广内容违法或侵权，导致折一收到权利人或相关人的任何追诉或索赔，或收到相关政府部门的任何形式的处罚或承担任何形式的责任时，用户应承担由此产生的全部法律责任，并使折一免责。如果折一依法先行赔付的，有权向实际违规用户追偿，追偿范围包括但不限于赔付第三方的费用、支付的罚款、折一为实现债权支付的合理费用（含律师费、诉讼费等）。</p>
      <p>4.本《协议》签订地为杭州。本《协议》的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和折一之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交折一所在地即杭州市有管辖权的人民法院管辖。</p>
      <p>折一在此声明，您通过本软件参加的任何商业活动，与Apple Inc.无关。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'userAgreement',
    created () {
      document.title = '折一 - 用户协议'
    }
  }
</script>

<style lang='less' scoped>
  #user-agreement {
    .title {
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      margin-top: 20px;
    }
    .content {
      padding: 20px;
      p {
        text-indent: 2em;
      }
    }
  }
</style>
